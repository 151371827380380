export const DsCategoriaCnh = [
  { id: 0, descricao: ''},
  { id: 1, descricao: 'A' },
  { id: 2, descricao: 'B' },
  { id: 3, descricao: 'C' },
  { id: 4, descricao: 'AB' },
  { id: 5, descricao: 'D' },
  { id: 6, descricao: 'E' }
];

export interface InterfaceCategoriaCnh {
  id : number;
  descricao : string;
}

export interface ITurnoPretendido {
  id : string;
  descricao : string;
}

export enum NivelIdioma {
  basico = 1,
  intermediario = 2,
  avancado = 3
}

export const DsNivelIdioma = [
  { id: 1, descricao: 'Básico' },
  { id: 2, descricao: 'Intermediário' },
  { id: 3, descricao: 'Avançado' }
];