import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from './notifications/notification.service';
import { MatDialogModule } from '@angular/material';
import { MaskDirective } from './directives/mask.directive';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  imports: [CommonModule, MatDialogModule, FlexLayoutModule],
  declarations: [ MaskDirective ],
  providers: [ NotificationService ],
  exports: [ MaskDirective ]
})
export class HelpersModule {}
