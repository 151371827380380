import { NgRxAction } from '@bo/store/ngrx-action';
import { Endereco } from '@bo/shared/models/ofertante/endereco.model';


export const ENDERECO_SELECIONADO_LOAD = '[ENDERECO_SELECIONADO] LOAD';
export const ENDERECO_SELECIONADO_LOADED = '[ENDERECO_SELECIONADO] LOADED';
export const ENDERECO_SELECIONADO_CRIAR = '[ENDERECO_SELECIONADO_CRIAR] CRIAR';
export const ENDERECO_SELECIONADO_CRIADO = '[ENDERECO_SELECIONADO_CRIADO] CRIADO';


export class EnderecoSelecionadoLoadAction implements NgRxAction<Endereco> {
  readonly type = ENDERECO_SELECIONADO_LOAD;

  constructor() {}
}

export class EnderecoSelecionadoLoadedAction implements NgRxAction<Endereco> {
  readonly type = ENDERECO_SELECIONADO_LOADED;

  constructor(public payload: Endereco) {}
}

export class CriarEnderecoSelecionadoAction implements NgRxAction<Endereco> {
  readonly type = ENDERECO_SELECIONADO_CRIAR;

  constructor(public payload: Endereco) {}
}

export class EnderecoSelecionadoCriadoAction implements NgRxAction<Endereco> {
  readonly type = ENDERECO_SELECIONADO_CRIADO;

  constructor(public payload: Endereco) {}
}


export type EnderecoSelecionadoActions = EnderecoSelecionadoLoadAction
  | EnderecoSelecionadoLoadedAction
  | CriarEnderecoSelecionadoAction
  | EnderecoSelecionadoCriadoAction
;
