import { IModel } from "@senac/architecture";
import { StatusCandidato } from "@bo/shared/models/enums/candidato.enum";
import { PessoaFisica } from "@bo/shared/models/ofertante/pessoa-fisica.model";
import { Curriculo } from "@bo/shared/models/curriculo/curriculo.model";

export class Candidato implements IModel {
  public id : string;
  public pessoaFisica : PessoaFisica;
  public curriculo : Curriculo;
  public estrangeiro : boolean;
  public status : StatusCandidato;
}

export interface CandidatoComplemento {
  estrangeiro : boolean;
}