import { AbstractControl } from '@angular/forms';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'senac-field-validator',
  templateUrl: './field-validator.component.html',
  styleUrls: ['./field-validator.component.css']
})
export class FieldValidatorComponent implements OnChanges {

  @Input()
  inputErrors: any;
  @Input()
  errorDefs: any;
  @Input()
  formControler: AbstractControl;

  errorMessage = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes || !changes.inputErrors) return;

    const errors = changes.inputErrors.currentValue;
    this.errorMessage = '';

    if (errors) {
      Object.keys(this.errorDefs).some(key => {
        if (errors[key] && this.formControler.dirty) {
          this.errorMessage = this.errorDefs[key];
          return true;
        }
      });
    }
  }
}
