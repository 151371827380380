import { authOfertanteActions } from './../actions/auth-ofertante.action';
import * as fromAuthOfertante from '../actions/auth-ofertante.action';
import { Ofertante } from "@bo/shared/models/ofertante/ofertante.model";
import { ResultPaginated } from "@senac/architecture";

export const INIT_STATE: ResultPaginated<Ofertante> = undefined;

export const reducerAuthOfertante = (state = INIT_STATE, action: fromAuthOfertante.authOfertanteActions): ResultPaginated<Ofertante> => {

  switch (action.type) {
    case fromAuthOfertante.AUTH_OFERTANTE_LOADED_ALL:
        return action.payload;

    default:
      return state;
  }
};