import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ModalMaterialConfirmComponent } from './modal-material-confirm/modal-material-confirm.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    RouterModule
  ],
  declarations: [ ModalMaterialConfirmComponent, NotFoundComponent ],
  exports: [ ModalMaterialConfirmComponent, NotFoundComponent ],
  entryComponents: [ ModalMaterialConfirmComponent ]
})
export class LayoutComponentsModule {}
