import { MatDialog, MatDialogRef } from '@angular/material';
import { Inject  } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ModalMaterialConfirmComponent } from '../../../../layout-components/src/lib/modal-material-confirm/modal-material-confirm.component';

@Injectable()
export class NotificationService {

    constructor(
        @Inject(MatDialog) private dialogMaterial: MatDialog
    ) {
    }

    successAdd(msg?: string): void {
        
    }

    successRemove(msg?: string): void {
      
    }

    successUpdate(msg?: string): void {
      
    }

    success(msg: string): void {
      
    }

    info(msg: string): void {
      
    }

    warning(msg: string): void {
      
    }

    error(msg: string): void {
      
    }

    modalAlert(title: string, message: string, icon = null, okLabel = 'Ok', obsBeforeClose = true): Observable<boolean> {
        return this.modalConfirm(title, message, icon, okLabel, false, obsBeforeClose);
    }

    modalConfirm(title: string, message: string, icon = null, okLabel = 'Ok', showCancelButton = true, obsBeforeClose = false):
      Observable<boolean> {

        let dialogRef: MatDialogRef<ModalMaterialConfirmComponent>;

        dialogRef = this.dialogMaterial.open(ModalMaterialConfirmComponent);

        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.okLabel = okLabel;
        dialogRef.componentInstance.showCancelButton = showCancelButton;
        dialogRef.componentInstance.setIcon(icon);

        return obsBeforeClose ? dialogRef.beforeClose() : dialogRef.afterClosed();
    }
}
