import { AdminGuard } from '@bo/shared/authentication/guard/admin.guard';
import { OfertanteGuard } from './shared/authentication/guard/ofertante.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import 'hammerjs';

import { NxModule } from '@nrwl/nx';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { AppStoreModule } from '@bo/store/app-store.module';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AppComponent } from './app.component';
import { HelpersModule } from '@senac/helpers';
import { LayoutComponentsModule } from '@senac/layout-components';
import { HTTP_INTERCEPTORS } from '../../../../node_modules/@angular/common/http';
import { AuthGuard } from '@bo/shared/authentication/guard/auth.guard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTPListener, HTTPStatus } from '@bo/shared/bases/http-interceptor.service';
import { AuthBOService } from '@bo/shared/authentication/services/auth-bo.services';
import { AuthHttpInterceptorService } from '@bo/shared/authentication/interceptors/auth-http-interceptor.service';
import { StoreModule } from '@ngrx/store';
import * as fromShared from './shared/components/store';
import { SharedComponentsModule } from '@bo/shared/components/shared-components.module';
import { CandidatoGuard } from '@bo/shared/authentication/guard/candidato.guard';

const RxJS_Services = [HTTPListener, HTTPStatus];

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NxModule.forRoot(),
    RouterModule.forRoot([]), 
    AppRoutingModule,
    MatNativeDateModule,
    MatSnackBarModule,
    AppStoreModule,
    HttpClientModule,
    HelpersModule,
    LayoutComponentsModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    SharedComponentsModule,
    StoreModule.forFeature('shared', fromShared.reducers),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    AuthGuard,
    CandidatoGuard,
    OfertanteGuard,
    AdminGuard,
    ...RxJS_Services,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptorService, multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    AuthBOService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
