import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { Cidade } from '@bo/shared/models/localidade/cidade.model';
import { ServiceAbstract, ResponseSuccess } from '@senac/architecture';

@Injectable()
export class CidadeService extends ServiceAbstract<Cidade> {

  getEndpoint() : string {
    return 'cidade';
  }

  getCidadesByTermo(termo : string): Observable<Cidade[]> {
    return this.http.get<ResponseSuccess<Cidade[]>>(`${this.getUri()}/por-descricao/${termo}`, this.getOptions())
      .map((res) => res.data)
      .catch(error => of())
  }

  getEnvironment() {
    return environment;
  }

}
