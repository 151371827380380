import { InscricaoProcessoSeletivo } from './../../models/processo-seletivo/inscricao-processo-seletivo.model';
import { Candidato } from '@bo/shared/models/candidato/candidato.model';
import { Component, OnInit, Input } from "@angular/core";
import { ICardCandidatoAction } from "@bo/shared/components/card-candidato/card-candidato-action.interface";
import { IconEnum } from "@senac/helpers";
import { Curriculo } from '@bo/shared/models/curriculo/curriculo.model';
import { PessoaFisica } from '@bo/shared/models/ofertante/pessoa-fisica.model';
import { DsStatusInscricaoProcessoSeletivo, StatusInscricaoProcessoSeletivo } from '@bo/shared/models/enums/inscricao-processo-seletivo.enum';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'bo-card-candidato',
  templateUrl: './card-candidato.component.html',
  styleUrls: ['./card-candidato.component.scss'],
})
export class CardCandidatoComponent implements OnInit {

  avatar: string = "";
  
  @Input()
  public actions?: ICardCandidatoAction[];

  @Input()
  public candidato: Candidato;

  @Input()
  public inscricao: InscricaoProcessoSeletivo;

  public icons = IconEnum;

  constructor() {}

  ngOnInit() {
    
    if (!this.actions)
      this.actions = [];

    if (!this.candidato) {
      this.candidato = new Candidato();
      this.candidato.pessoaFisica = new PessoaFisica();
      this.candidato.curriculo = new Curriculo();
    }

    if (this.inscricao) {
      this.candidato = this.inscricao.candidato;
    }

    this.avatar = `${environment.appApi.avatarPath}/${this.candidato.pessoaFisica.foto}`;
  }

  action(actionFunc: Function, candidato: Candidato): void {
    if (this.inscricao)
      actionFunc(this.inscricao);
    else
      actionFunc(candidato);
  }

  isVisible(actionFunc: Function): boolean {
    if (actionFunc) {

      if (this.inscricao)
        return actionFunc(this.inscricao);
      else
        return actionFunc(this.candidato);

    }
    
    return true;
  }

  getColor = (color: string): string => color ? color : '#000000';

  formatResumo(): string {
    
    if (this.inscricao) {
      this.candidato = this.inscricao.candidato;
    }

    if (this.candidato.curriculo.resumoProfissional && this.candidato.curriculo.resumoProfissional.length <= 93)
      return this.candidato.curriculo.resumoProfissional;

    if (this.candidato.curriculo.resumoProfissional)
      return this.candidato.curriculo.resumoProfissional.slice(0, 93).concat('...');

    return "";
  }

  getDescricaoStatus(): string {
    if (this.inscricao) {
      return DsStatusInscricaoProcessoSeletivo.find(i => i.id === this.inscricao.status).descricao;
    }

    return "";
  }

  getPosicao(): string {

    if (
      this.inscricao &&
      this.inscricao.posicao &&
      (this.inscricao.status == StatusInscricaoProcessoSeletivo.Contratado || this.inscricao.status == StatusInscricaoProcessoSeletivo.CadastroDeReserva)
    ) {
      return "#" + this.inscricao.posicao.toString();
    }

    return "";
  }
}
