import { environment } from './../../../../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from '@senac/authentication';
import { AuthBOService } from '@bo/shared/authentication/services/auth-bo.services';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private authBoService: AuthBOService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.isAuthenticated(state.url);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.isAuthenticated(state.url);
    }

    private isAuthenticated(path: string): Observable<boolean> {
        return this.authBoService.isAuthenticated().map(isAuthenticated => {
            if (!isAuthenticated) {
                this.authBoService.logoutToLogin(path);
                return false;
            }
            return isAuthenticated;
        });
    }
}
