import * as fromRoot from '../../../store/app.reducer';
import * as fromEnderecoSelecionado from './reducers/endereco-selecionado.reducer';
import * as fromCidade from './reducers/cidade.reducer';
import * as fromNotificacao from './reducers/notificacao.reducer';
import * as fromUserAuthenticated from './reducers/user-authenticated.reducer';
import * as fromAuthOfertante from './reducers/auth-ofertante.reducer';
import { Endereco } from '@bo/shared/models/ofertante/endereco.model';
import { Cidade } from '@bo/shared/models/localidade/cidade.model';
import { Notificacao } from '@bo/shared/models/notificacao/notificacao.model';
import { ResultPaginated } from '@senac/architecture';
import { UserAuthenticated } from '@senac/authentication';
import { User } from '@bo/shared/models/authentication/user.model';
import { Ofertante } from '@bo/shared/models/ofertante/ofertante.model';

export interface State extends fromRoot.AppState {
  'endereco' : Endereco,
  'cidades' : Cidade[],
  'quantidadeNotificacoes': number,
  'notificacoes' : ResultPaginated<Notificacao>,
  'userAuthenticated' : UserAuthenticated<User, any>,
  'authOfertante' : ResultPaginated<Ofertante>;
}

export const reducers = {
  'endereco' : fromEnderecoSelecionado.reducerEnderecoSelecionado,
  'cidades' : fromCidade.reducerCidades,
  'notificacoes' : fromNotificacao.reducerNotificacoes,
  'quantidadeNotificacoes' : fromNotificacao.reducerQuantidadeNotificacoes,
  'userAuthenticated' : fromUserAuthenticated.reducerUserAuthenticated,
  'authOfertante' : fromAuthOfertante.reducerAuthOfertante
}
