import { AuthBOService } from '@bo/shared/authentication/services/auth-bo.services';
import { OnInit, Component, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { Ofertante } from "@bo/shared/models/ofertante/ofertante.model";
import { MatDialog, MatTableDataSource, MatPaginator } from "@angular/material";
import { Store, select, ActionsSubject } from "@ngrx/store";
import * as fromShared from '@bo/shared/components/store';
import { of, Subscription } from "rxjs";
import { AuthOfertanteLoadAllAction, AUTH_OFERTANTE_LOADED_ALL, AuthOfertanteLoadedAllAction } from "@bo/shared/components/store/actions/auth-ofertante.action";
import { map, filter } from "rxjs/operators";
import { ResultPaginated } from "@senac/architecture";
import { ofType } from "@ngrx/effects";
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'bo-modal-auth-ofertante',
  templateUrl: './modal-auth-ofertante.component.html',
  styleUrls: ['./modal-auth-ofertante.component.scss'],
})
export class ModalAuthOfertanteComponent implements OnInit, OnDestroy {

  ofertantes$: Observable<MatTableDataSource<Ofertante>>;
  displayedColumns: string[] = ['nome', 'cpfCnpj', 'actions'];
  loadSub: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('cpf') cpfViewChild : ElementRef;
  @ViewChild('cnpj') cnpjViewChild : ElementRef;
  pageIndex = 0;
  pageSize = 5;
  length = 0;
  formFilter: FormGroup;

  constructor(
    public _dialog: MatDialog,
    private _store: Store<Ofertante[]>,
    private _actionsSubject: ActionsSubject,
    private authBoService: AuthBOService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.formFilter = new FormGroup({
      nome: new FormControl(),
      cpf: new FormControl(),
      cnpj: new FormControl()
    });

    this.paginate();
  }

  ngOnDestroy(): void {
    this.loadSub.unsubscribe();
  }

  paginate(length = this.length, pageIndex = this.pageIndex, pageSize = this.pageSize): void {
    this._store.dispatch(new AuthOfertanteLoadAllAction(pageIndex + 1, pageSize, this.formFilter.getRawValue()));

    this.loadSub = this._actionsSubject.asObservable()
      .pipe(
        ofType(AUTH_OFERTANTE_LOADED_ALL),
        map((action: AuthOfertanteLoadedAllAction) => {
          this.paginator.pageSize = pageSize;
          this.paginator.pageIndex = pageIndex;
          this.paginator.length = action.payload.total;

          return action.payload.results;
        })
      ).subscribe((ofertantes) => {
        this.ofertantes$ = of(new MatTableDataSource(ofertantes));
      });
  }

  changePage = (evt) => this.paginate(evt.length, evt.pageIndex, evt.pageSize);

  acessarComoOfertante(ofertante: Ofertante): void {
    this.authBoService.loginAsOfertante(ofertante.id)
      .subscribe(() => this._dialog.closeAll());
  }

  limparFiltro() {
    this.formFilter.patchValue({
      'cpf': null,
      'nome': null,
      'cnpj': null
    });

    this.cpfViewChild.nativeElement.value = '';
    this.cnpjViewChild.nativeElement.value = '';

    this.paginate();
  }

  searchByCNPJ(): void {
    this.formFilter.patchValue({
      'cpf': null,
      'nome': null
    });

    this.cpfViewChild.nativeElement.value = '';
  }

  searchByCPF(): void {
    this.formFilter.patchValue({
      'cnpj': null,
      'nome': null
    });

    this.cnpjViewChild.nativeElement.value = '';
  }

  searchByNome(): void {
    this.formFilter.patchValue({
      'cpf': null,
      'cnpj': null,
    });

    this.cpfViewChild.nativeElement.value = '';
    this.cnpjViewChild.nativeElement.value = '';
  }
}