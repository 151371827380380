import { NgRxAction } from '@bo/store/ngrx-action';
import { Notificacao } from '@bo/shared/models/notificacao/notificacao.model';
import { ResultPaginated } from '@senac/architecture';

export const QUANTIDADE_NOTIFICACOES_LOAD = '[QUANTIDADE_NOTIFICACOES] LOAD';
export const QUANTIDADE_NOTIFICACOES_LOADED = '[QUANTIDADE_NOTIFICACOES] LOADED';

export const NOTIFICACAO_LOAD = '[NOTIFICACAO] LOAD';
export const NOTIFICACAO_LOADED = '[NOTIFICACAO] LOADED';

export const NOTIFICACOES_MARCAR_COMO_LIDA = '[NOTIFICACOES] MARCAR COMO LIDA';
export const NOTIFICACOES_MARCADAS_COMO_LIDA = '[NOTIFICACOES] MARCADAS COMO LIDAS';

export class QuantidadeNotificacoesLoadAction implements NgRxAction<number> {
  readonly type = QUANTIDADE_NOTIFICACOES_LOAD;

  constructor(public tipo: number) { }
}

export class QuantidadeNotificacoesLoadedAction implements NgRxAction<number> {
  readonly type = QUANTIDADE_NOTIFICACOES_LOADED;

  constructor(public payload: number) { }
}

export class NotificacaoLoadAction implements NgRxAction<Notificacao[]> {
  readonly type = NOTIFICACAO_LOAD;

  constructor(public page: number, public size: number, public tipo: number) { }
}

export class NotificacaoLoadedAction implements NgRxAction<ResultPaginated<Notificacao>> {
  readonly type = NOTIFICACAO_LOADED;

  constructor(public payload: ResultPaginated<Notificacao>) { }
}

export class NotificacoesMarcarComoLidaAction implements NgRxAction<number> {
  readonly type = NOTIFICACOES_MARCAR_COMO_LIDA;

  constructor(public notificacoes: Notificacao[]) { }
}

export class NotificacoesMarcadasComoLidaAction implements NgRxAction<Notificacao[]> {
  readonly type = NOTIFICACOES_MARCADAS_COMO_LIDA;

  constructor(public notificacoes: Notificacao[]) { }
}

export type NotificacaoActions = NotificacaoLoadAction
  | QuantidadeNotificacoesLoadAction
  | QuantidadeNotificacoesLoadedAction
  | NotificacaoLoadAction
  | NotificacaoLoadedAction
  | NotificacoesMarcarComoLidaAction
  | NotificacoesMarcadasComoLidaAction
;