import { SidebarComponent } from './../sidebar/sidebar.component';
import { Component, OnInit, Input, Output, ElementRef, ViewChild } from '@angular/core';
import { AuthBOService } from '@bo/shared/authentication/services/auth-bo.services';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { IconEnum } from '@senac/helpers';

@Component({
  selector: 'senac-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isCorporativo: boolean;
  isAluno: boolean;
  icons = IconEnum;

  @Input()
  sidenav: SidebarComponent;

  @Input()
  func: Function;

  @Input()
  isAdministrador: boolean = false;

  constructor(
    private authService: AuthBOService,
    private route: Router
  ) {
  }

  ngOnInit() {

  }

  toggleSidebar() {
    this.sidenav.toggle();
  }

  onLoggedout = () => this.authService.logoutToLogin(this.route.url, true);

  changePassword = () => this.authService.redirectChangePassword();
}
