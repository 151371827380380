import { ObservableMedia, MediaChange } from '@angular/flex-layout';

export function updateFullSizeModal(ref, media: ObservableMedia) : void {

    const subscription = media.subscribe((change: MediaChange) => {
        switch (change.mqAlias) {
          case 'xs':
            ref.updateSize('100%', '100%');
            break;
          case 'sm':
          case 'md':
          case 'lg':
            ref.updateSize('75%', '100%');
            break;
          case 'xl':
            ref.updateSize('50%', '100%');
            break;
        }
    });

    ref.beforeClose().subscribe(() => {
        subscription.unsubscribe();
    });
}