export enum TipoCnpj {
  Cnpj = 1,
  Cei = 2
}

export const DsTipoCnpj = [
  { id: 1, descricao: 'CNPJ' },
  { id: 2, descricao: 'CEI' }
];

export enum TipoEmpresa {
  ComFinsLucrativos = 1,
  SemFinsLucrativos = 2,
  Estadual = 3,
  Municipal = 4,
  Federal = 5,
  SistemaS = 6,
  Ong = 7,
  Outros = 8
}

export const DsTipoEmpresa = [
  {id: 1, descricao : 'Com fins lucrativos' },
  {id: 2, descricao : 'Sem fins lucrativos' },
  {id: 3, descricao : 'Estadual' },
  {id: 4, descricao : 'Municipal' },
  {id: 5, descricao : 'Federal' },
  {id: 6, descricao : 'Sistema S' },
  {id: 7, descricao : 'ONG' },
  {id: 8, descricao : 'Outros' }
];

export enum TipoEndereco {
  Residencial = 1,
  Comercial = 2
}

export const DsTipoEndereco = [
  { id: 1, descricao: 'Residencial' },
  { id: 2, descricao: 'Comercial' }
];

export const DsUf = [
  { id: 1, descricao: 'GO' },
  { id: 2, descricao: 'SC' },
  { id: 3, descricao: 'SP' },
  { id: 4, descricao: 'RS' }
];
