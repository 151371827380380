import * as fromActions from '../actions/notificacao.actions';
import { ResultPaginated } from '@senac/architecture';
import { Notificacao } from '@bo/shared/models/notificacao/notificacao.model';
import { StatusNotificacao } from '@bo/shared/models/enums/notificacao.enum';

export const INIT_QTD_STATE: number = 0;

export const reducerQuantidadeNotificacoes = (state = INIT_QTD_STATE, action: fromActions.NotificacaoActions): number => {

  switch (action.type) {
    case fromActions.QUANTIDADE_NOTIFICACOES_LOADED:
      return action.payload;

    case fromActions.NOTIFICACOES_MARCADAS_COMO_LIDA:
      const naoLidas = state - action.notificacoes.length;
      return naoLidas && naoLidas > 0 ? naoLidas : 0;

    default:
      return state;
  }
};

export const INIT_STATE_ALL: ResultPaginated<Notificacao> = null;

export const reducerNotificacoes = (state = INIT_STATE_ALL, action: fromActions.NotificacaoActions): ResultPaginated<Notificacao> => {

  switch (action.type) {
    case fromActions.NOTIFICACAO_LOADED:
      return action.payload;

    case fromActions.NOTIFICACOES_MARCADAS_COMO_LIDA:
      if (state && state.results) {
        let array = [] = state.results.filter(n => n.status === StatusNotificacao.NaoVisualizada);
        for (let i = 0; i < array.length; i++) {
          array[i].status = StatusNotificacao.Visualizada;
        }
      }
      return state;

    default:
      return state;
  }
};
