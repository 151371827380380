import { IModel } from "@senac/architecture";
import { ProcessoSeletivo } from "./processo-seletivo.model";
import { Candidato } from "../candidato/candidato.model";
import { StatusInscricaoProcessoSeletivo } from "../enums/inscricao-processo-seletivo.enum";

export class InscricaoProcessoSeletivo implements IModel
{
  public id: string;
  public processoSeletivo : ProcessoSeletivo;
  public processoSeletivoId : string;
  public candidato: Candidato;
  public status: StatusInscricaoProcessoSeletivo;
  public dataInscricao: Date;
  public posicao?: number;
  public feedback?: string;
}
