import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable, of } from 'rxjs';
import { ServiceAbstract, ResponseSuccess, ResultPaginated, ResponseSuccessPaginated } from '@senac/architecture';
import { environment } from '../../../../environments/environment';
import { Notificacao } from '@bo/shared/models/notificacao/notificacao.model';
import { ProcessoSeletivo } from '../processo-seletivo/processo-seletivo.model';
import { StatusNotificacao } from '../enums/notificacao.enum';
import { CustomHttpParams } from '../../../../../../../libs/architecture/src/lib/bases/custom-http-params';

@Injectable()
export class NotificacaoService extends ServiceAbstract<Notificacao> {

  getEndpoint() {
    return 'notificacao';
  }

  getEnvironment() {
    return environment;
  }

  getQuantidadeByTipo(tipo: number): Observable<number> {
    return this.http.get<ResponseSuccess<number>>(`${this.getUri()}/count-notificacoes-nao-lidas?tipo=${tipo}`, this.getOptions())
      .map((res) => res.data)
      .catch(error => of());
  }

  getByTipo(page: number = 1, pageSize: number, tipo: number): Observable<ResultPaginated<Notificacao>> {
    
    return this.http.get<ResponseSuccessPaginated<Notificacao>>(
      `${this.getUri()}/por-tipo?page=${page}&pageSize=${pageSize}&tipo=${tipo}`,
      this.getOptionsWithoutSpinner()
    )
      .map((res) => res.data)
      .catch(error => of());
  }

  marcarComoLida(notificacoes: Notificacao[]): Observable<Notificacao[]> {
    const array = [];
    for (let i = 0; i < notificacoes.length; i++) {
      array.push(notificacoes[i].id);
    }

    const data = {
      notificacoes: array
    };

    return this.http.post<ResponseSuccess<Notificacao[]>>(`${this.getUri()}/marcar-como-lida`, JSON.stringify(data), this.getOptions())
      .map((res) => notificacoes)
      .catch(error => of())
  }

  getPaginatedByProcessoSeletivo(page: number = 1, pageSize: number, processoSeletivo: ProcessoSeletivo): Observable<ResultPaginated<Notificacao>> {
    return this.http.get<ResponseSuccessPaginated<Notificacao>>(`${this.getUri()}/por-processo-seletivo?page=${page}&pageSize=${pageSize}&processoSeletivoId=${processoSeletivo.id}`, this.getOptionsWithoutSpinner())
      .map((res) => res.data)
      .catch(error => of());
  }

}