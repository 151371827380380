import { TipoEndereco } from '../enums/ofertante.enum';
import { Pessoa } from '@bo/shared/models/ofertante/pessoa.model';
import { IModel } from '@senac/architecture';
import { Cidade } from '@bo/shared/models/localidade/cidade.model';

export class Endereco implements IModel
{
  public id : string;
  public tipo : TipoEndereco;
  public logradouro : string;
  public numero : string;
  public complemento : string;
  public bairro : string;
  public cidade : Cidade;
  public cep : string;
  public pessoa? : Pessoa;
}
