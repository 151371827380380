// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appApi: {
    baseUrl: 'https://apibo.go.senac.br/api/v1',
    imageRoot : 'https://apibo.go.senac.br/images',
    avatarPath : 'https://apibo.go.senac.br/images/avatar'
  },
  authServer: {
baseUrl: 'https://auth.go.senac.br',
    login: '/connect/authorize',
    logout: '/account/logout',
    changePassword: '/account/changepassword',
    client: {
      clientId: 'banco-oportunidades'
    }
  },
  servidorCep: {
    baseUrl: 'https://viacep.com.br/ws',
    tipoRetorno: 'json'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
