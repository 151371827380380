import * as fromEndereco from '../actions/endereco-selecionado.actions';
import { Endereco } from '@bo/shared/models/ofertante/endereco.model';

export const INIT_STATE_ENDERECO_SELECIONADO : Endereco = null;

export const reducerEnderecoSelecionado = (state = INIT_STATE_ENDERECO_SELECIONADO, action: fromEndereco.EnderecoSelecionadoActions): Endereco => {

  switch (action.type) {
    case fromEndereco.ENDERECO_SELECIONADO_LOADED:
    case fromEndereco.ENDERECO_SELECIONADO_CRIADO:
      return action.payload;

    default:
      return state;
  }
};
