import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material';
import { StatusNotificacao, TipoNotificacao } from '@bo/shared/models/enums/notificacao.enum';
import { Notificacao } from '@bo/shared/models/notificacao/notificacao.model';
import { AppState } from '@bo/store/app.reducer';
import { select, Store } from '@ngrx/store';
import { ResultPaginated } from '@senac/architecture';
import { IconEnum } from '@senac/helpers';
import { Observable, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as fromShared from '../store';
import { NotificacaoLoadAction, NotificacoesMarcarComoLidaAction, QuantidadeNotificacoesLoadAction } from '../store/actions/notificacao.actions';

@Component({
  selector: 'bo-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})
export class NotificacoesComponent implements OnInit, OnDestroy {

  @ViewChild(MatMenu)menu: MatMenu;
  
  @Input() tipoNotificacao: number = TipoNotificacao.Nenhum;
  
  quantidadeNotificacoes$: Observable<number>;
  notificacoes$: Observable<ResultPaginated<Notificacao>>;

  timer: Observable<any> = timer(2000, 60 * 1000); //tempo em milissegundos
  timerSub: Subscription;
  menuSub: Subscription;

  icons = IconEnum;

  pageIndex = 0;
  pageSize = 5;
  length = 0;

  constructor(
    private _store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.timerSub = this.timer.subscribe(() => {
      this._store.dispatch(new QuantidadeNotificacoesLoadAction(this.tipoNotificacao));
      this.definePaginator();
    });

    this.quantidadeNotificacoes$ = this._store.pipe(
      select('shared'),
      map((state: fromShared.State) => state.quantidadeNotificacoes)
    );

    this.menuSub = this.menu.closed.subscribe(e => {

      this._store.pipe(
        select('shared'),
        map((state: fromShared.State) => state.notificacoes),
        distinctUntilChanged()
      ).subscribe((e: ResultPaginated<Notificacao>) => {
        const notificacoes = e.results.filter(x => x.status !== StatusNotificacao.Visualizada);
        if (notificacoes.length > 0)
          this._store.dispatch(new NotificacoesMarcarComoLidaAction(notificacoes));
      })
      .unsubscribe();
    });
  }

  ngOnDestroy() {
    this.timerSub.unsubscribe();
    this.menuSub.unsubscribe();
  }

  definePaginator = (pageIndex = this.pageIndex, pageSize = this.pageSize) => {
    this._store.dispatch(new NotificacaoLoadAction(pageIndex, pageSize, this.tipoNotificacao));

    this.notificacoes$ = this._store.pipe(
      select('shared'),
      map((state: fromShared.State) => state.notificacoes),
      distinctUntilChanged()
    );
  }

  changePage = (evt) => this.definePaginator(evt.pageIndex, evt.pageSize);

  loadNotificacoes() {
    this.pageSize += 5;
    this._store.dispatch(new NotificacaoLoadAction(this.pageIndex, this.pageSize, this.tipoNotificacao));
  }

}
