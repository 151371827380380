export enum IconEnum {
    Announcement = 'announcement',
    Assignment = 'assignment',
    AddLocation = 'add_location',
    AddCircle = 'add_circle',
    AccountCircle = 'account_circle',
    Account = 'account_box',
    AssignmentInd = 'assignment_ind',
    Add = 'add',
    ArrowBack = 'arrow_back_ios',
    ArrowForward = 'arrow_forward_ios',
    AddList = 'fast_forward',
    Calendar = 'calendar_today',
    City = 'location_city',
    Copy = 'content_copy',
    Check = 'check',
    Checkbox = 'check_box',
    CheckboxOutlineBlank = 'check_box_outline_blank',
    Dashboard = 'dashboard',
    Business = 'business',
    Block = 'block',
    BusinessCenter = 'business_center',
    Clone = 'flip_to_front',
    CloudUpload = 'cloud_upload',
    ContactEmail = 'contact_mail',
    Delete = 'delete_forever',
    Devices = 'devices',
    DateReange = 'date_range',
    Disabled = 'block',
    Description = 'description',
    Edit = 'mode_edit',
    Email = 'email',
    EventSeat = 'event_seat',
    Exit = 'exit_to_app',
    ExpandLess = 'arrow_upward',
    ExpandMore = 'arrow_downward',
    Enabled = 'check_circle_outline',
    Extension = 'extension',
    FileDownload = 'file_download',
    FilterList = 'filter_list',
    FindInPage = 'find_in_page',
    Gps = 'gps_fixed',
    ImportantDevices = 'important_devices',
    ImportContacts = 'import_contacts',
    Info = 'info_outline',
    KeyboardArrowDown = 'keyboard_arrow_down',
    Link = 'link',
    ListMaterial = 'list',
    LocationCity = 'location_city',
    LockOutline = 'lock_outline',
    Lock = 'lock',
    MailOutline = 'mail_outline',
    Menu = 'menu',
    More = 'more',
    MoreVert = 'more_vert',
    Notifications = 'notifications',
    People = 'people',
    Print = 'print',
    PlaylistAdd = 'playlist_add_check',
    Person = 'person',
    PeopleOutline = 'people_outline',
    PermContactCalendar = 'perm_contact_calendar',
    Place = 'place',
    PowerSettingsNew = 'power_settings_new',
    RemoveList = 'fast_rewind',
    Repeat = 'repeat',
    Recibo = 'receipt',
    Reply = 'reply',
    RateReview = 'rate_review',
    Search = 'search',
    Save = 'save',
    School = 'school',
    todos = 'check_box',
    Touch = 'touch_app',
    Translate = 'g_translate',
    ViewList = 'view_list',
    Work = 'work',
    TrendingUp = 'trending_up',
    ThumbUp = 'thumb_up',
    ThumbDown = 'thumb_down',
    recibo = 'receipt',
    NoteAdd = 'note_add',
    Visibility = 'visibility',
    Close = 'close',
    Gavel = 'gavel',
    Swap = 'swap_horiz',
    BarChart = 'bar_chart'
}
