import * as fromCidades from '../actions/cidade.actions';
import { Cidade } from '@bo/shared/models/localidade/cidade.model';


export const INIT_STATE_CIDADES : Cidade[] = null;

export const reducerCidades = (state = INIT_STATE_CIDADES, action: fromCidades.CidadeActions): Cidade[] => {
  switch (action.type) {
    case fromCidades.CIDADE_AUTOCOMPLETE_LOADED:
      return action.payload;

    default:
      return state;
  }
};
