export class FileUtils {

  static base64ToArrayBuffer(base64: any): any {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  static download(fileName: string, fileType: string, content: any): void {

    let fileContent = this.base64ToArrayBuffer(content),
      blob = new Blob([fileContent], {type: fileType}),
      url = URL.createObjectURL(blob),
      a = document.createElement('a');
  
    a.setAttribute('style', 'display:none;');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    a.parentNode.removeChild(a);
  }

}
