import { NivelIdioma, DsNivelIdioma, ITurnoPretendido } from '@bo/shared/models/enums/curriculo.enum';
import { Candidato } from '@bo/shared/models/candidato/candidato.model';
import { Component, OnInit, Inject } from "@angular/core";
import { ICardCandidatoAction } from "@bo/shared/components/card-candidato/card-candidato-action.interface";
import { IconEnum } from "@senac/helpers";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { InscricaoProcessoSeletivo } from '@bo/shared/models/processo-seletivo/inscricao-processo-seletivo.model';
import { of, Observable, isObservable } from 'rxjs';
import { User } from '@bo/shared/models/authentication/user.model';
import { UserAuthenticated } from '@senac/authentication';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { DsTipoEndereco } from '@bo/shared/models/enums/ofertante.enum';

export interface CurriculoModal {
  actions?: ICardCandidatoAction[];
  candidato?: Candidato;
  inscricaoProcessoSeletivo?: InscricaoProcessoSeletivo;
}

@Component({
  selector: 'bo-curriculo-modal',
  templateUrl: './curriculo-modal.component.html',
  styleUrls: ['./curriculo-modal.component.scss'],
})
export class CurriculoModalComponent implements OnInit {

  avatar: string;

  areas: string;

  public actions?: ICardCandidatoAction[];

  public candidato: Candidato;
  public dsTipoEndereco;

  public icons = IconEnum;

  constructor(
    public dialogRef: MatDialogRef<CurriculoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public curriculoModal: CurriculoModal,
    private _store: Store<UserAuthenticated<User, any>>,
  ) {

    if (curriculoModal.inscricaoProcessoSeletivo) {
      this.candidato = curriculoModal.inscricaoProcessoSeletivo.candidato;
    } else {
      this.candidato = curriculoModal.candidato;
    }

    if (this.candidato.pessoaFisica.enderecos && this.candidato.pessoaFisica.enderecos.length > 0) {
      let auxEndereco = DsTipoEndereco.find(d => d.id === this.candidato.pessoaFisica.enderecos[0].tipo);
      this.dsTipoEndereco = auxEndereco ? auxEndereco.descricao : '';
    } else {
      this.dsTipoEndereco = '';
    }

    this.actions = curriculoModal.actions;
    this.areas = this.candidato.curriculo.areaInteresses.map(x => x.descricao).join(', ');
  }

  ngOnInit() {
    this.setAvatar();
  }

  action(actionFunc: Function): void {
    let res = undefined;
    if (this.curriculoModal.inscricaoProcessoSeletivo) {
      res = actionFunc(this.curriculoModal.inscricaoProcessoSeletivo);
    }
    else {
      res = actionFunc(this.candidato);
    }

    if (typeof res === typeof of(InscricaoProcessoSeletivo)) {
      (res as Observable<InscricaoProcessoSeletivo>).subscribe((inscricao: InscricaoProcessoSeletivo) => {
        this.curriculoModal.inscricaoProcessoSeletivo = inscricao;
        this.candidato = inscricao.candidato;

        this.setAvatar();
      })
    }
  }

  isVisible(actionFunc: Function): Observable<boolean> {
    if (actionFunc) {

      let result = null;
      if (this.curriculoModal.inscricaoProcessoSeletivo)
        result = actionFunc(this.curriculoModal.inscricaoProcessoSeletivo);
      else
        result = actionFunc(this.candidato);

      if (isObservable(result))
        return result as Observable<boolean>;
      else
        return of(result);
    }
    
    return of(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  getColor = (color: string): string => color ? color : '#000000';

  getIdiomaNivel(nivel: NivelIdioma): string {
    return DsNivelIdioma.find(n => n.id === nivel).descricao;
  }

  setAvatar(): void {
    this.avatar = `${environment.appApi.avatarPath}/${this.candidato.pessoaFisica.foto}`;
  }

  listarTurnos(turnos: Array<ITurnoPretendido>){
    return turnos.map(turno => turno.descricao).join(',');
  }

  mostrarInformacoesComplementares(){
    return this.candidato.estrangeiro ||
           this.candidato.curriculo.emailAlternativo ||
           this.candidato.curriculo.linkPaginaProfissional ||
           this.candidato.curriculo.possuiCnh ||
           (this.candidato.curriculo.turnosPretendidos &&
            this.candidato.curriculo.turnosPretendidos.length > 0);
  }

}
