import { ErrorHandling } from "@bo/shared/bases/error-handling.abstract";
import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { Action } from "@ngrx/store";
import { ResultPaginated } from "@senac/architecture";
import { switchMap, map } from "rxjs/operators";
import { NotificacaoService } from "@bo/shared/models/services/notificacao.service";
import { NOTIFICACAO_LOAD, NotificacaoLoadAction, NotificacaoLoadedAction, QUANTIDADE_NOTIFICACOES_LOAD, QuantidadeNotificacoesLoadAction, QuantidadeNotificacoesLoadedAction, NOTIFICACOES_MARCAR_COMO_LIDA, NotificacoesMarcarComoLidaAction, NotificacoesMarcadasComoLidaAction } from "../actions/notificacao.actions";
import { Notificacao } from "@bo/shared/models/notificacao/notificacao.model";

@Injectable()
export class NotificacaoEffects extends ErrorHandling {

  @Effect()
  loadQuantidadeNotificacoes$: Observable<Action> = this.actions$.pipe(
    ofType(QUANTIDADE_NOTIFICACOES_LOAD),
    switchMap((action: QuantidadeNotificacoesLoadAction) => this.notificacaoService.getQuantidadeByTipo(action.tipo)),
    map((quantidade: number) => new QuantidadeNotificacoesLoadedAction(quantidade)),
  );

  @Effect()
  loadNotificacoes$: Observable<Action> = this.actions$.pipe(
    ofType(NOTIFICACAO_LOAD),
    switchMap((action: NotificacaoLoadAction) => this.notificacaoService.getByTipo(action.page + 1, action.size, action.tipo)),
    map((notificacoes: ResultPaginated<Notificacao>) => new NotificacaoLoadedAction(notificacoes)),
  );

  @Effect()
  visualizarNotificacoes$: Observable<Action> = this.actions$.pipe(
    ofType(NOTIFICACOES_MARCAR_COMO_LIDA),
    switchMap((action: NotificacoesMarcarComoLidaAction) => this.notificacaoService.marcarComoLida(action.notificacoes)),
    map((notificacoes: Notificacao[]) => new NotificacoesMarcadasComoLidaAction(notificacoes)),
  );

  constructor(
    public actions$: Actions,
    private notificacaoService: NotificacaoService,
  ) {
    super(actions$);
  }

}