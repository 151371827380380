import { Injectable } from "@angular/core";
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from "@ngrx/effects";
import { switchMap, map } from "rxjs/operators";
import { ErrorHandling } from '@bo/shared/bases/error-handling.abstract';
import { ENDERECO_SELECIONADO_CRIAR, CriarEnderecoSelecionadoAction, EnderecoSelecionadoCriadoAction, EnderecoSelecionadoLoadAction, ENDERECO_SELECIONADO_LOAD, EnderecoSelecionadoLoadedAction } from "@bo/shared/components/store/actions/endereco-selecionado.actions";
import { Observable } from "rxjs";
import { Endereco } from "@bo/shared/models/ofertante/endereco.model";
import { EnderecoService } from "@bo/shared/models/services/endereco.service";

@Injectable()
export class EnderecoSelecionadoEffects extends ErrorHandling {

  @Effect()
  criarEndereco$: Observable<Action> = this.actions$.pipe(
    ofType(ENDERECO_SELECIONADO_CRIAR),
    switchMap((action: CriarEnderecoSelecionadoAction) => {
      if (action.payload.id !== undefined && action.payload.id !== null) {
        return this.enderecoService.update(action.payload);
      }

      delete action.payload.id;
      
      return this.enderecoService.add(action.payload);
    }),
    map((endereco: Endereco) => endereco.id ? new EnderecoSelecionadoCriadoAction(endereco) : new EnderecoSelecionadoLoadAction()),
  );
  
  @Effect()
  loadEndereco$: Observable<Action> = this.actions$.pipe(
    ofType(ENDERECO_SELECIONADO_LOAD),
    switchMap((action: EnderecoSelecionadoLoadAction) => this.enderecoService.getAll()),
    map((enderecos: Endereco[]) => new EnderecoSelecionadoLoadedAction(enderecos[0])),
  );

  constructor(
    public actions$ : Actions,
    private store: Store<any>,
    public enderecoService: EnderecoService
  ) {
    super(actions$);
  }
}
