import { NgRxAction } from '@bo/store/ngrx-action';
import { UserAuthenticated } from '@senac/authentication';
import { User } from '@bo/shared/models/authentication/user.model';

export const USER_AUTHENTICATED_LOAD = '[USER_AUTHENTICATED] LOAD';
export const USER_AUTHENTICATED_LOADED = '[USER_AUTHENTICATED] LOADED';

export const USER_AUTHENTICATED_REFRESH = '[USER_AUTHENTICATED] REFRESH';
export const USER_AUTHENTICATED_REFRESHED = '[USER_AUTHENTICATED] REFRESHED';

export class UserAuthenticatedLoadAction implements NgRxAction<UserAuthenticated<User, any>> {
  readonly type = USER_AUTHENTICATED_LOAD;

  constructor() { }
}

export class UserAuthenticatedLoadedAction implements NgRxAction<UserAuthenticated<User, any>> {
  readonly type = USER_AUTHENTICATED_LOADED;

  constructor(public payload: UserAuthenticated<User, any>) { }
}

export class UserAuthenticatedRefreshAction implements NgRxAction<UserAuthenticated<User, any>> {
  readonly type = USER_AUTHENTICATED_REFRESH;

  constructor() { }
}

export class UserAuthenticatedRefreshedAction implements NgRxAction<UserAuthenticated<User, any>> {
  readonly type = USER_AUTHENTICATED_REFRESHED;

  constructor(public payload: UserAuthenticated<User, any>) { }
}


export type UserAuthenticationActions = UserAuthenticatedLoadAction
  | UserAuthenticatedLoadedAction
  | UserAuthenticatedRefreshAction
  | UserAuthenticatedRefreshedAction
;