import { UserAuthenticated } from './../../../../authentication/src/lib/models/user-authenticated.model';
import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MatSidenav } from '@angular/material';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { filter } from 'rxjs/operators/filter';
import { IMenu } from './menu.interface';
import { ModalChangeAvatarComponent } from './modal-change-avatar/modal-change-avatar.component';
import { Observable } from 'rxjs/internal/Observable';
import { User } from './model/user.model';

@Component({
  selector: 'senac-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isActive = false;
  showMenu = '';
  maxScreenWidthClosed = 1160;
  screenWidth: number;
  mode = 'side';
  isMobile = false;
  modalAvatarRef : MatDialogRef<ModalChangeAvatarComponent>;
  tooltipText: string = "Alterar Foto";
  pathName : string = window.location.pathname;

  @ViewChild('sidenav') sidenav: MatSidenav;

  @Input() menus: IMenu[];
  @Input() avatarPath: string;
  @Input() userAuthenticated$: Observable<UserAuthenticated<User, any>>;

  @Input() canChangeAvatar: boolean = true;
  @Output() onChangeAvatar = new EventEmitter<string>();

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private media: ObservableMedia,
    private _dialog: MatDialog,
  ) {
    this.media
    .asObservable()
    .pipe(
      filter((change: MediaChange) => change.mqAlias === 'xs')
    )
    .subscribe(() => {
      this.mode = 'over';
      this.isMobile = true;
    });
    
    this.media
    .asObservable()
    .pipe(
      filter((change: MediaChange) => change.mqAlias !== 'xs')
    )
    .subscribe(() => {
        this.mode = 'side';
        this.isMobile = false;
    });

    this.screenWidth = window.innerWidth;

    window.onresize = () => {
        this.screenWidth = window.innerWidth;
        this.cdr.detectChanges();
    };
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((v : NavigationStart) => {
      this.pathName = v.url;
    });

    if (!this.canChangeAvatar)
      this.tooltipText = "";
  }

  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
        this.showMenu = "0";
    } else {
        this.showMenu = element;
    }
  }

  abrirModalEditarFoto(evt) {
    evt.preventDefault();

    if (this.canChangeAvatar) {
      this.modalAvatarRef = this._dialog.open(ModalChangeAvatarComponent);
      this.modalAvatarRef.afterClosed().subscribe(() => {
        if (this.modalAvatarRef.componentInstance.getImage()) {
          this.salvarImagem(this.modalAvatarRef.componentInstance.getImage());
        }
      });
    }
  }

  sidebarNavigate(link): void {
      if (this.screenWidth <= this.maxScreenWidthClosed) {
          this.sidenav.close();
      }
      this.router.navigate([link], { relativeTo: this.route });
  }

  toggle() {
      this.sidenav.toggle();
  }

  salvarImagem(base64: string): void {
    if (this.canChangeAvatar)
      this.onChangeAvatar.emit(base64);
  }
}
