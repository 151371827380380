import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: string) : string {
    return `(${value.substr(0, 2)}) ${value.substr(2, 5)}-${value.substr(7, 11)}`;
  }

}
