import * as fromActions from '../actions/user-authenticated.actions';
import { UserAuthenticated } from '@senac/authentication';
import { User } from '@bo/shared/models/authentication/user.model';

export const INIT_STATE: UserAuthenticated<User, any> = null;

export const reducerUserAuthenticated = (state = INIT_STATE, action: fromActions.UserAuthenticationActions): UserAuthenticated<User, any> => {

  switch (action.type) {
    case fromActions.USER_AUTHENTICATED_LOADED:
    case fromActions.USER_AUTHENTICATED_REFRESHED:
      return action.payload;

    default:
      return state;
  }
};
