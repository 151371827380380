import { Component, Inject } from '@angular/core';
import { AuthBOService } from '@bo/shared/authentication/services/auth-bo.services';
import { MAT_DIALOG_DATA } from '@angular/material';
import { RoleEnum } from '@bo/shared/authentication/enums/role.enum';

interface DataUnauthorize {
  errors: string[];
  role: RoleEnum;
}

@Component({
  selector: 'bo-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {

  roles = RoleEnum;

  constructor(
    private authBoService: AuthBOService,
    @Inject(MAT_DIALOG_DATA) public data: DataUnauthorize
  ) {}

  logout = () => {
    if (this.roles.Candidato == this.data.role)
      this.authBoService.logoutToLogin('portal-candidato', true);
    else if (this.roles.AdministradorDr == this.data.role || this.roles.AdministradorUnidade == this.data.role)
      this.authBoService.logoutToLogin('admin', true);
  } 
    
}