import { OfertanteQuery } from './../../../models/ofertante/ofertante-query.model';
import { Ofertante } from '@bo/shared/models/ofertante/ofertante.model';
import { NgRxAction } from "@bo/store/ngrx-action";
import { ResultPaginated } from '@senac/architecture';

export const AUTH_OFERTANTE_LOAD_ALL = '[AUTH_OFERTANTE] LOAD';
export const AUTH_OFERTANTE_LOADED_ALL = '[AUTH_OFERTANTE] LOADED';

export class AuthOfertanteLoadAllAction implements NgRxAction<Ofertante[]> {
readonly type = AUTH_OFERTANTE_LOAD_ALL;

constructor(public page: number, public size: number, public ofertanteQuery: OfertanteQuery) { }
}

export class AuthOfertanteLoadedAllAction implements NgRxAction<ResultPaginated<Ofertante>> {
readonly type = AUTH_OFERTANTE_LOADED_ALL;

constructor(public payload: ResultPaginated<Ofertante>) { }
}

export type authOfertanteActions = AuthOfertanteLoadAllAction
| AuthOfertanteLoadedAllAction
;