import { NotificationService } from '@senac/helpers';
import { environment } from './../../../../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from '@senac/authentication';
import { AuthBOService } from '@bo/shared/authentication/services/auth-bo.services';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class CandidatoGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private authBoService: AuthBOService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.isCandidato();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.isCandidato();
    }

    private isCandidato(): boolean {
        if (this.authBoService.isCandidato()) {
            return true;
        } else {
            this.snackBar.open('Você não tem permissão para acessar esta parte da aplicação.', 'Ok');
            this.authBoService.redirectWhenLoggedin();
            return false;
        }
    }
}