import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefoneFixo'
})
export class TelefoneFixoPipe implements PipeTransform {

  transform(value: string) : string {
    return `(${value.substr(0, 2)}) ${value.substr(2, 4)}-${value.substr(6, 10)}`;
  }

}
