import { RoleEnum } from '@bo/shared/authentication/enums/role.enum';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpSentEvent } from '@angular/common/http';
import { HttpHeaderResponse } from '@angular/common/http';
import { HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { HttpUserEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthBOService } from '@bo/shared/authentication/services/auth-bo.services';
import 'rxjs/add/operator/mergeMap';
import { catchError, map, filter } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ModalDesclassificarCandidatoComponent } from '@bo/portal-ofertante/painel-controle-ofertante/modal-desclassificar-candidato/modal-desclassificar-candidato.component';
import { CurriculoModalComponent } from '@bo/shared/components/curriculo-modal/curriculo-modal.component';
import { UnauthorizedComponent } from '@bo/shared/components/noauth-candidato/unauthorized.component';

@Injectable()
export class AuthHttpInterceptorService implements HttpInterceptor {

    private authService: AuthBOService;
    private route: Router;
    private snackBar: MatSnackBar;
    private dialog: MatDialog;

    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        this.authService = this.injector.get(AuthBOService);
        this.route = this.injector.get(Router);
        this.snackBar = this.injector.get(MatSnackBar);
        this.dialog = this.injector.get(MatDialog);

        if (!this.authService.ignore(req.url)) {
            return this.authService.isAuthenticated()
                .flatMap(isAuthenticated => {
                    if (isAuthenticated) {
                        const duplicate = req.clone(
                            {
                                headers: req.headers.set(
                                    'Authorization',
                                    this.authService.getToken().tokenType + ' ' + this.authService.getToken().accessToken
                                )
                            });
                        return next.handle(duplicate);

                    } else {
                        this.authService.logoutToLogin(this.route.url);
                        return next.handle(req);
                    }
                });
        }

        return next.handle(req).pipe(
            map((e: HttpEvent<any> | HttpErrorResponse) => {
                if ((<HttpErrorResponse>e).status == 401) {
                    let errors = [];
                    if ((<HttpErrorResponse>e).error)
                        errors = (<HttpErrorResponse>e).error.errors;
                    if (window.location.href.includes('/pc?')) {
                        this.dialog.open(UnauthorizedComponent, { data: { role: RoleEnum.Candidato, errors: (<HttpErrorResponse>e).error.errors }, disableClose: true, height: '50%', width: '60%', maxWidth: '60%' });
                    } else if (window.location.href.includes('/admin')) {
                        this.dialog.open(UnauthorizedComponent, { data: { role: RoleEnum.AdministradorUnidade, errors: (<HttpErrorResponse>e).error.errors }, disableClose: true, height: '50%', width: '60%', maxWidth: '60%' });
                    } else {
                        this.snackBar.open("Este usuário não possui permissões para acessar o Banco de Oportunidades!");
                        window.setTimeout(this.authService.logoutToLogin.bind(this.authService, this.route.url, true), 5000);
                    }
                }

                return e;
            }),
            catchError((response: any) => of(response))
        );
    }
}
