import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import {
  MatSidenavModule,
  MatTooltipModule,
  MatIconModule,
  MatListModule,
  MatDialogModule,
  MatButtonModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ModalChangeAvatarComponent } from './modal-change-avatar/modal-change-avatar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    FlexLayoutModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    ImageCropperModule
  ],
  declarations: [
    SidebarComponent,
    ModalChangeAvatarComponent
  ],
  exports: [
    SidebarComponent
  ],
  entryComponents: [ModalChangeAvatarComponent]
})
export class SidebarModule {}
