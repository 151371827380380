import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper/src/image-cropper.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: "bo-modal-change-avatar",
  templateUrl: "./modal-change-avatar.component.html",
  styleUrls: ["./modal-change-avatar.component.scss"]
})
export class ModalChangeAvatarComponent implements OnInit, OnDestroy {

  form : FormGroup;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;
  isPortalCandidato = false;

  constructor(
    public _dialog: MatDialog,
    public http: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    this.isPortalCandidato = this.router.url.includes('portal-candidato');

    this.form = new FormGroup({
      imagem: new FormControl(null, Validators.required)
    });
  }

  ngOnDestroy() {
    this._dialog.closeAll()
  }

  getImage() {
    return this.form.get('imagem').value;
  }

  salvar(evt : any) : void {
    evt.preventDefault();

    this.form.get('imagem').setValue(this.croppedImage);

    if (this.form.valid) {
      this._dialog.closeAll();
    }
  }

  changeFileAvatar = (event: any) : void  => this.imageChangedEvent = event;

  imageCropped = (event: ImageCroppedEvent) => this.croppedImage = event.base64;

  imageLoaded = () => this.cropperReady = true;

  loadImageFailed = () => console.log('Load failed');

  cancelar = () => {
    this._dialog.closeAll();
    return false;
  }
}
