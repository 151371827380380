import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@bo/shared/authentication/guard/auth.guard';
import { AdminGuard } from '@bo/shared/authentication/guard/admin.guard';
import { CandidatoGuard } from '@bo/shared/authentication/guard/candidato.guard';
import { OfertanteGuard } from '@bo/shared/authentication/guard/ofertante.guard';
import { NotFoundComponent } from '@senac/layout-components';

const routes: Routes = [
  { 
    path: 'portal-candidato', 
    loadChildren: './portal-candidato/portal-candidato.module#PortalCandidatoModule',
    canActivate: [AuthGuard, CandidatoGuard],
    canActivateChild: [AuthGuard, CandidatoGuard]
  },
  { 
    path: 'portal-ofertante', 
    loadChildren: './portal-ofertante/portal-ofertante.module#PortalOfertanteModule',
    canActivate: [AuthGuard, OfertanteGuard],
    canActivateChild: [AuthGuard, OfertanteGuard]
  },
  { 
    path: 'admin', loadChildren: './admin/admin.module#AdminModule',
    canActivate: [AuthGuard, AdminGuard],
    canActivateChild: [AuthGuard, AdminGuard]
  },
  { 
    path: 'authorization-code', loadChildren: './shared/authentication/authorization-code/authorization-code.module#AuthorizationCodeModule'
  },
  { 
    path: 'home', redirectTo: ''
  },
  { 
    path: '', loadChildren: './home/home.module#HomeModule'
  },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
