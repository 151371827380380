import { ClaimEnum } from './../enums/claim.enum';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { HttpServiceAbstract, ResponseSuccess } from '@senac/architecture';
import { Portal } from '@bo/shared/authentication/enums/portal.enum';
import { AuthenticationService, UserAuthenticated, Token } from '@senac/authentication';
import { HttpClient } from '@angular/common/http';
import { RoleEnum } from '@bo/shared/authentication/enums/role.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthorizationCode } from '@senac/authentication';
import { User } from '@bo/shared/models/authentication/user.model';
import { UserAuthenticatedRefreshAction } from '@bo/shared/components/store/actions/user-authenticated.actions';
import { Store } from '@ngrx/store';


@Injectable()
export class AuthBOService extends HttpServiceAbstract {

  /**
   *
   */
  constructor(
    protected httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private _store: Store<UserAuthenticated<User, any>>
  ) {
    super(httpClient);
  }

  getEnvironment() {
    return environment;
  }

  getEndpoint() {
    return 'token';
  };

  getPortal(path: string): string {
    if (path.includes('portal-ofertante') || path.includes('/po?'))
      return Portal.PortalOfertante;
    else if (path.includes('portal-candidato') || path.includes('/pc?'))
      return Portal.PortalCandidato;
    else  if (path.includes('admin'))
      return Portal.Admin;
    else
      return '';
  }

  isPessoaJuridica(): Observable<boolean> {
    return this.getUserAuthenticated().map(user => {
      return user.user.isPessoaJuridica;
    });
  }

  isOfertante() {
    return this.authService.hasRole(RoleEnum.Ofertante) || this.isLoggedAsOfertante();
  }

  isCandidato() {
    return this.authService.hasRole(RoleEnum.Candidato);
  }

  isAdministrador() {
    return this.authService.hasRole(RoleEnum.AdministradorDr) || this.authService.hasRole(RoleEnum.AdministradorUnidade);
  }

  isAdministradorDr() {
    return this.authService.hasRole(RoleEnum.AdministradorDr);
  }

  isAdministradorUnidade() {
    return this.authService.hasRole(RoleEnum.AdministradorUnidade);
  }

  isAdministradorUsuario() {
    return this.authService.hasRole(RoleEnum.AdministradorUsuario);
  }

  isAdministradorVisitante() {
    return this.authService.hasRole(RoleEnum.AdministradorVisitante);
  }

  redirectPrimeiroAcesso() {
    if (this.isCandidato()) {
      this.router.navigate(['portal-candidato/curriculo']);
    }
    else if (this.isOfertante()) {
      this.router.navigate(['portal-ofertante/ofertante']);
    }
  }

  redirect(portal: Portal) {
    switch(portal) {
      case Portal.Admin:
        this.router.navigate(['admin']);
        break;

      case Portal.PortalCandidato:
        this.router.navigate(['portal-candidato']);
        break;

      case Portal.PortalOfertante:
        this.router.navigate(['portal-ofertante']);
        break;
    }
  }

  redirectWhenLoggedin(portal: Portal = undefined) {

    if (portal) {
      this.redirect(portal);
      return;
    }

    if (this.isAdministrador()) {
        this.router.navigate(['admin']);
        return;
    }

    if (this.isOfertante()) {
      this.router.navigate(['portal-ofertante']);
      return;
    }

    if (this.isCandidato()) {
      this.router.navigate(['portal-candidato']);
      return;
    } else {
      this.logoutToLogin(this.router.url);
    }
  }

  loginAsOfertante(ofertanteId: string): Observable<any> {
    return this.http.post(`${environment.appApi.baseUrl}/user-authenticated/${ofertanteId}`, {}, this.getOptions())
    .map(() => this.refreshToken().subscribe(() => this.router.navigate(['portal-ofertante'])));
  }

  logoutAsOfertante(): Observable<ResponseSuccess<any>> {
    const ofertanteId = this.authService.getClaim(ClaimEnum.OfertanteId);
    return this.http.post<ResponseSuccess<any>>(`${environment.appApi.baseUrl}/user-authenticated/ofertante/logout/${ofertanteId}`, {}, this.getOptions())
    .map((data) => {
      this.refreshToken().subscribe(() => {
        this._store.dispatch(new UserAuthenticatedRefreshAction());
        this.router.navigate(['admin'])
      });
      return data;
    });
  }

  isLoggedAsOfertante(): boolean {
    return this.authService.getClaim(ClaimEnum.OfertanteId) !== undefined;
  }

  getUserAuthenticated(): Observable<UserAuthenticated<User, any>> {
    const userAuthenticated$ = this.http.get<ResponseSuccess<UserAuthenticated<User, any>>>(`${environment.appApi.baseUrl}/user-authenticated`, this.getOptions())
      .map(res => res.data);
    return this.authService.getUserAuthenticated(userAuthenticated$);
  }

  getRefreshUserAuthenticated(): Observable<UserAuthenticated<User, any>> {
    const userAuthenticated$ = this.http.get<ResponseSuccess<UserAuthenticated<User, any>>>(`${environment.appApi.baseUrl}/user-authenticated`, this.getOptions())
      .map(res => res.data);
    return this.authService.getRefreshUserAuthenticated(userAuthenticated$);
  }

  getTokenByAuthorizationCode(authorizationCode: AuthorizationCode<any>): Observable<UserAuthenticated<any, any>> {
    const $token = this.http.post<ResponseSuccess<Token>>(`${environment.appApi.baseUrl}/${this.getEndpoint()}/authorization-code`, JSON.stringify(authorizationCode), this.getOptions())
    .map(res => {
      return this.mapper(res);
    });

    return this.authService.login($token);
  }

  getToken(): Token {
    return this.authService.getToken();
  }

  isPrimeiroAcesso(): boolean {
    const token = this.authService.getToken();
    if(token)
      return token.primeiroAcesso;
    return false;
  }

  refreshToken(): Observable<Token> {
    const refreshToken = this.authService.getRefreshToken();

    if (refreshToken && refreshToken.refreshToken) {
      const $refreshToken = this.http.post<ResponseSuccess<Token>>(`${environment.appApi.baseUrl}/${this.getEndpoint()}/refreshToken`, JSON.stringify(refreshToken), this.getOptions())
      .map(res => res.data);
      return this.authService.refreshToken($refreshToken);
    }

    return undefined;
  }

  logout(): void {
    this.authService.logout();
  }

  logoutToLogin(pathPortal: string, flagRedirectLogout:boolean = false): void {
    let redirectUrl = "";

    this.logout();

    if (flagRedirectLogout === true) {
      redirectUrl = `${environment.authServer.baseUrl}${environment.authServer.logout}?postLogout=${window.location.origin}/authorization-code/${this.getPortal(pathPortal)}`;
    } else {
      redirectUrl = `${environment.authServer.baseUrl}${environment.authServer.login}?client_id=${environment.authServer.client.clientId}&response_type=code`
      + `&scope=offline_access&redirect_uri=${window.location.origin}/authorization-code/${this.getPortal(pathPortal)}`;
    }

    window.location.href = redirectUrl;
  }

  redirectChangePassword() : void {
    window.open(`${environment.authServer.baseUrl}${environment.authServer.changePassword}?redirect_uri=${window.location.href}`, '_blank');
  }

  isAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated(this.refreshToken());
  }

  getIgnoreAuthEndpoint(): string[] {
    return ['token', 'token/authorization-code'];
  }

  ignore(url: string) {

    // arquivos de configuracao
    if (url.indexOf('assets/config') !== -1) {
        return true;
    }

    if (!url.startsWith(environment.appApi.baseUrl)) {
        return true;
    }
    for (let endpoint of this.getIgnoreAuthEndpoint()) {
        if (url.toLowerCase().startsWith(`${environment.appApi.baseUrl}/${endpoint.toLowerCase()}/`) ||
            url.toLowerCase().startsWith(`${environment.appApi.baseUrl}/${endpoint.toLowerCase()}`)) {
            return true;
        }
    }

    return false;
}

}
