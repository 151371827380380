import { ModalAuthOfertanteComponent } from './modal-auth-ofertante/modal-auth-ofertante.component';
import { CurriculoModalComponent } from './curriculo-modal/curriculo-modal.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatIconModule,
  MatAutocompleteModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatListModule,
  MatDialogModule,
  MatMenuModule,
  MatBadgeModule,
  MatTableModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatExpansionModule,
  MatChipsModule
} from '@angular/material';
import { CardCandidatoComponent } from './card-candidato/card-candidato.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnderecoComponent } from '@bo/shared/components/endereco/endereco.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromShared from './store';
import { EnderecoSelecionadoEffects } from '@bo/shared/components/store/effects/endereco-selecionado.effect';
import { CidadeEffects } from '@bo/shared/components/store/effects/cidade.effect';
import { CepPipe } from '@bo/shared/pipes/cep.pipe';
import { TelefonePipe } from '@bo/shared/pipes/telefone.pipe';
import { TelefoneFixoPipe } from '@bo/shared/pipes/telefoneFixo.pipe';
import { HelpersModule, FieldValidatorModule } from '@senac/helpers';
import { ReactiveFormsModule } from '@angular/forms';
import { CardProcessoSeletivoComponent } from '@bo/shared/components/card-processo-seletivo/card-processo-seletivo.component';
import { EnderecoService } from '@bo/shared/models/services/endereco.service';
import { CidadeService } from '@bo/shared/models/services/cidade.service';
import { NotificacoesComponent } from './notificacoes/notificacoes.component';
import { NotificacaoEffects } from './store/effects/notificacao.effect';
import { NotificacaoService } from '../models/services/notificacao.service';
import { AuthBOService } from '@bo/shared/authentication/services/auth-bo.services';
import { CpfPipe } from '@bo/shared/pipes/cpf.pipe';
import { BooleanPipe } from '@bo/shared/pipes/boolean.pipe';
import { CnpjPipe } from '@bo/shared/pipes/cnpj.pipe';
import { UnauthorizedComponent } from '@bo/shared/components/noauth-candidato/unauthorized.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    HelpersModule,
    FieldValidatorModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
    MatBadgeModule,
    MatTableModule,
    MatExpansionModule,
    StoreModule.forFeature('shared', fromShared.reducers),
    EffectsModule.forFeature([
      EnderecoSelecionadoEffects,
      CidadeEffects,
      NotificacaoEffects
    ]),
  ],
  declarations: [
    CardCandidatoComponent,
    CurriculoModalComponent,
    CardProcessoSeletivoComponent, 
    CepPipe,
    CpfPipe,
    CnpjPipe,
    TelefonePipe,
    TelefoneFixoPipe,
    BooleanPipe,
    EnderecoComponent,
    NotificacoesComponent,
    ModalAuthOfertanteComponent,
    UnauthorizedComponent
  ],
  entryComponents: [
    CardCandidatoComponent,
    CurriculoModalComponent,
    CardProcessoSeletivoComponent,
    ModalAuthOfertanteComponent,
    UnauthorizedComponent
  ],
  exports: [ 
    CardCandidatoComponent,
    CurriculoModalComponent,
    EnderecoComponent,
    CardProcessoSeletivoComponent,
    CepPipe,
    CpfPipe,
    CnpjPipe,
    TelefonePipe,
    TelefoneFixoPipe,
    BooleanPipe,
    NotificacoesComponent,
    ModalAuthOfertanteComponent,
    UnauthorizedComponent
  ],
  providers: [
    CidadeService,
    EnderecoService,
    NotificacaoService,
    AuthBOService
  ]
})
export class SharedComponentsModule { }
