import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldValidatorComponent } from './field-validator.component';
import { MatInputModule, MatCardModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatCardModule
  ],
  declarations: [FieldValidatorComponent],
  exports: [FieldValidatorComponent]
})
export class FieldValidatorModule { }
