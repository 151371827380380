import { Pessoa } from '@bo/shared/models/ofertante/pessoa.model';
import { IModel } from '@senac/architecture';
import { Deficiencia } from '@bo/shared/models/pessoa/deficiencia.model';

export class PessoaFisica extends Pessoa implements IModel
{
  public id : string;
  public nome : string;
  public cpf : string;
  public rg : string;
  public orgaoExpedidor : string;
  public dataExpedicao : Date;
  public dataNascimento : Date;
  public telefone : string;
  public email : string;
  public estrangeiro : boolean;
  public portadorDeficiencia : boolean;
  public foto : string;
  public deficiencias: Deficiencia[];
  public outrasDeficiencias: string;
}
