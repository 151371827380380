import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, of } from 'rxjs';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import * as fromShared from '../store';
import { Cidade } from '@bo/shared/models/localidade/cidade.model';
import { Endereco } from '@bo/shared/models/ofertante/endereco.model';
import { DsTipoEndereco } from '@bo/shared/models/enums/ofertante.enum';
import { CidadeBuscarTermoAction } from '@bo/shared/components/store/actions/cidade.actions';
import { map, distinctUntilChanged, filter } from 'rxjs/operators';
import { CriarEnderecoSelecionadoAction } from '@bo/shared/components/store/actions/endereco-selecionado.actions';
import { MatSnackBar } from '@angular/material';

interface AutoCompleteCidade {
  uf : string;
  cidades: Cidade[]
}

@Component({
  selector: 'bo-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss']
})
export class EnderecoComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() endereco: Endereco;

  @ViewChild('cep') cep : ElementRef;

  dsTipoEndereco = DsTipoEndereco;

  cidadesFiltradas$ : Observable<AutoCompleteCidade[]>;

  loadSubAutocomplete: Subscription;
  loadEnderecoSub: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private _store : Store<Endereco>,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {

    this.form = this._formBuilder.group({
      cep : new FormControl(this.endereco ? this.endereco.cep : null, Validators.required),
      tipo : new FormControl(this.endereco ? this.endereco.tipo : null, Validators.required),
      logradouro : new FormControl(this.endereco ? this.endereco.logradouro : null, Validators.required),
      numero : new FormControl(this.endereco ? this.endereco.numero : null, Validators.maxLength(10)),
      complemento : new FormControl(this.endereco ? this.endereco.complemento : null),
      bairro : new FormControl(this.endereco ? this.endereco.bairro : null, Validators.required),
      cidade : new FormControl(this.endereco ? this.endereco.cidade : null, Validators.required),
      id : new FormControl(this.endereco ? this.endereco.id : null)
    });

    this._store.pipe(
      select('shared'),
      map((data : fromShared.State) => data.endereco),
      filter((endereco : Endereco) => endereco !== undefined && endereco !== null)
    ).subscribe((endereco : Endereco) => {

      this.form.get('cep').setValue(endereco.cep);
      this.form.get('tipo').setValue(endereco.tipo);
      this.form.get('logradouro').setValue(endereco.logradouro);
      this.form.get('numero').setValue(endereco.numero);
      this.form.get('complemento').setValue(endereco.complemento);
      this.form.get('bairro').setValue(endereco.bairro);
      this.form.get('cidade').setValue(endereco.cidade);
      this.form.get('id').setValue(endereco.id);
    });

    this._store.pipe(
      select('shared'),
      map((data : fromShared.State) => data.cidades),
      distinctUntilChanged()
    ).subscribe((cidades : Cidade[]) => {

      let cidadesRender = [];

      if (cidades) {
        let estados = cidades.map(c => c.estado.descricao);

        cidadesRender = estados.filter((item, pos) => estados.indexOf(item) === pos)
          .map(e => ({uf : e, cidades: []}));

        for (const cidade of cidades) {
          const idx = cidadesRender.findIndex(e => e.uf === cidade.estado.descricao);

          cidadesRender[idx].cidades.push({ id : cidade.id, descricao: cidade.descricao });
        }
      }

      this.cidadesFiltradas$ = of(cidadesRender);
    });

    this.form.get('cidade').valueChanges
      .filter(val => val && val.length >= 2)
      .debounceTime(300)
      .distinctUntilChanged()
      .subscribe((value : string ) => this._store.dispatch(new CidadeBuscarTermoAction(value)));
  }

  salvar = () => {
    if (!this.form.valid) {
      this._snackBar.open('Erro! Verifique os campos obrigatórios!', 'Ok!');
      return false;
    }

    this._store.dispatch(new CriarEnderecoSelecionadoAction(this.form.getRawValue()));
  }

  exibirValorAutocompleteCidade(cidade?: Cidade): string | null {
    return cidade ? cidade.descricao : null;
  }

}
