import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HTTPStatus } from '@bo/shared/bases/http-interceptor.service';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import 'rxjs/add/operator/distinctUntilChanged';
import { map } from 'rxjs/operators';
import * as fromShared from './shared/components/store';
import { AppState } from './store/app.reducer';

@Component({
  selector: 'bo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  title: string = "BancoDeOportunidades";
  quantidadeNotificacoes$: Observable<number>;
  qtdNotificacoesSub: Subscription;

  HTTPActivity : boolean = true;
  activeAjaxConnections = 0;

  constructor(
    private httpStatus: HTTPStatus,
    private _store: Store<AppState>,
    private titleService: Title,
  ) { }

  ngOnInit(): void {

    this.setTitle(this.title);

    this.qtdNotificacoesSub = this._store.pipe(select('shared'),
      map((state: fromShared.State) => state.quantidadeNotificacoes)
    ).subscribe((qtd: number) => {
      if (qtd > 0) {
        this.setTitle(this.title + ` (${qtd})`);
      } else {
        this.setTitle(this.title);
      }
    });
  }

  ngOnDestroy(): void {
    this.qtdNotificacoesSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.httpStatus.getHttpStatus()
      .subscribe((status: boolean) => {

        if (status === false) {
          this.activeAjaxConnections++;
        }
        else if (status === true && this.activeAjaxConnections != 1) {
          this.activeAjaxConnections--;
        }

        this.HTTPActivity = (this.activeAjaxConnections <= 1);
      });
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

}
