import { Injectable } from "@angular/core";
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from "@ngrx/effects";
import { ErrorHandling } from '@bo/shared/bases/error-handling.abstract';
import { CIDADE_AUTOCOMPLETE_LOAD, CidadeBuscarTermoAction, CidadeEncontradasPorTermoAction } from "@bo/shared/components/store/actions/cidade.actions";
import { Observable } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { CidadeService } from "@bo/shared/models/services/cidade.service";
import { Cidade } from "@bo/shared/models/localidade/cidade.model";

@Injectable()
export class CidadeEffects extends ErrorHandling {

  @Effect()
  loadCidade$: Observable<Action> = this.actions$.pipe(
    ofType(CIDADE_AUTOCOMPLETE_LOAD),
    switchMap((action: CidadeBuscarTermoAction) => this.cidadeService.getCidadesByTermo(action.payload)),
    map((cidades: Cidade[]) => new CidadeEncontradasPorTermoAction(cidades)),
  );

  constructor(
    public actions$ : Actions,
    private store: Store<any>,
    private cidadeService: CidadeService
  ) {
    super(actions$);
  }
}
