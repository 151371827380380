import { Injectable } from '@angular/core';
import { ServiceAbstract } from '@senac/architecture';
import { environment } from '../../../../environments/environment';
import { Endereco } from '@bo/shared/models/ofertante/endereco.model';


@Injectable()
export class EnderecoService extends ServiceAbstract<Endereco> {
  
  getEndpoint() : string {
    return 'pessoa/endereco';
  }

  getEnvironment() {
    return environment;
  }
}
