import { IModel } from '@senac/architecture';
import { Endereco } from '@bo/shared/models/ofertante/endereco.model';

export class Contato {
  public nomeResponsavel : string;
  public emailResponsavel : string;
  public telefoneResponsavel : string;
}

export class RamoAtividade implements IModel {
  public id : string;
  public descricao: string;
  public tipo: number;
}

export abstract class Pessoa implements IModel
{
  public id : string;
  public ramoAtividade : RamoAtividade;
  public dataCadastro? : Date;
  public inscricaoMunicipal? : string;
  public email : string;
  public telefone : string;
  public celular : string;
  public contato? : Contato;
  public enderecos : Endereco[];
}
