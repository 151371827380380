import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, finalize, map } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import { CustomHttpParams } from '../../../../../../libs/architecture/src/lib/bases/custom-http-params';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material';


@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;

  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

interface IEvent {
  type : number;
  status : number;
  body : {
    mensagem : string
  }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {

  params : CustomHttpParams;

  constructor(
    private status: HTTPStatus,
    public snackBar: MatSnackBar
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.params = req.params;

    if (this.params.param.showSpinner === true) {
      this.status.setHttpStatus(false);
    }

    return next.handle(req).pipe(
      map((event : IEvent) => {

        if (event.type == 4 && req != undefined && req.method != 'GET' && event != undefined &&
          event.status != undefined && event.status == 200 &&
          event.body != undefined && event.body.mensagem != undefined &&
          this.params.param.showSpinner === true
        ) {
          this.snackBar.open(event.body.mensagem, 'Ok!');
        }
        return event;
      }),
      catchError((response: any) => {
        if (response.status != 401 && response.status >= 400 && response.status < 500) {

          if (response.error) {
            this.snackBar.open(response.error.errors.join('\r\n'), 'Ok!');

          } else if (response.message) {
            this.snackBar.open(response.message, 'Ok!');

          } else {
            this.snackBar.open('Ocorreu um erro inesperado!', 'Ok!');
          }

        }

        return of(response);
      }),
      finalize(() => {
        this.status.setHttpStatus(true);
      })
    )
  }
}
