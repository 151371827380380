import { Component, OnInit, Input } from "@angular/core";
import { ICardProcessoSeletivo } from '@bo/shared/components/card-processo-seletivo/card-processo-seletivo-action.interface';
import { ProcessoSeletivo } from '@bo/shared/models/processo-seletivo/processo-seletivo.model';
import { PessoaJuridica } from "@bo/shared/models/ofertante/pessoa-juridica.model";
import { PessoaFisica } from "@bo/shared/models/ofertante/pessoa-fisica.model";

@Component({
  selector: 'bo-card-processo-seletivo',
  templateUrl: './card-processo-seletivo.component.html',
  styleUrls: ['./card-processo-seletivo.component.scss'],
})
export class CardProcessoSeletivoComponent implements OnInit {

  @Input()
  public processoSeletivo: ProcessoSeletivo;

  @Input()
  public card? : ICardProcessoSeletivo;

  nomeOfertante: String;

  constructor() {
    this.card = {
      id: '',
      actionsCard: []
    };
  }

  ngOnInit() {
    let pj = <PessoaJuridica>this.processoSeletivo.ofertante.pessoa;
    let pf = <PessoaFisica>this.processoSeletivo.ofertante.pessoa;

    this.nomeOfertante = pf.nome ? pf.nome : pj.nomeFantasia;
  }

  action(actionFunc: Function, processoSeletivo: ProcessoSeletivo): void {
    actionFunc(processoSeletivo);
  }

  getColor = (color: string): string => color ? color : '#000000';

}
