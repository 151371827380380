import { Actions, Effect, ofType } from '@ngrx/effects';
import { ERROR_ACTION } from '@bo/store/app.actions';
import { Observable } from 'rxjs';

export abstract class ErrorHandling {

  @Effect({ dispatch: false })
  errorHandling$: Observable<any> = this.actions$.pipe(
    ofType(ERROR_ACTION)
  );

  constructor(
    public actions$ : Actions
  ) {}
}
