export enum TipoNotificacao {
  Nenhum = 0,
  Ofertante = 1,
  Candidato = 2
}

export const DsTipoNotificacao = [
  { id: 1, descricao: 'Ofertante' },
  { id: 2, descricao: 'Candidato' },
];

export enum StatusNotificacao {
  NaoVisualizada = 1,
  Visualizada = 2
}

export const DsStatusNotificacao = [
  { id: 1, descricao: 'Não Visualizada' },
  { id: 2, descricao: 'Visualizada' },
];