export enum StatusInscricaoProcessoSeletivo {
    Nenhum = 0,
    AguardandoAvaliacao = 1,
    Desclassificado = 2,
    Contratado = 3,
    CadastroDeReserva = 4
}

//Singular
export const DsStatusInscricaoProcessoSeletivo = [
  {id: 1, descricao : 'Aguardando Avaliação', class: 'waiting' },
  {id: 3, descricao : 'Contratado', class: 'success' },
  {id: 4, descricao : 'Cadastro de Reserva', class: 'waiting' },
  {id: 2, descricao : 'Desclassificado', class: 'warning' },
];

//Plural
export const DsStatusInscricaoProcessoSeletivoPlural = [
  {id: 1, descricao : 'Aguardando Avaliação' },
  {id: 3, descricao : 'Contratados' },
  {id: 4, descricao : 'Cadastro de Reserva' },
  {id: 2, descricao : 'Desclassificados' },
];
