import { IModel } from "@senac/architecture";
import { ITurnoPretendido } from "@bo/shared/models/enums/curriculo.enum";
import { Curso } from "@bo/shared/models/curriculo/curso.model";
import { ExperienciaProfissional } from "@bo/shared/models/curriculo/experiencia-profissional.model";
import { AreaInteresse } from "@bo/shared/models/processo-seletivo/area-interesse.model";
import { Idioma } from "@bo/shared/models/curriculo/idioma.model";
import { FormacaoAcademica } from "@bo/shared/models/curriculo/formacao-academica.model";


export class Curriculo implements IModel {
  public id : string;
  public experienciasProfissionais : ExperienciaProfissional[];
  public cursos : Curso[];
  public areaInteresses : AreaInteresse[];
  public formacoesAcademicas : FormacaoAcademica[];
  public idiomas : Idioma[];

  public disponibilidadeViagem : boolean;
  public linkPaginaProfissional : string;
  public possuiCnh : boolean;
  public categoria: string;
  public emailAlternativo : string;
  public resumoProfissional : string;
  public turnosPretendidos : ITurnoPretendido[];
}
