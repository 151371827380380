import { NgRxAction } from '@bo/store/ngrx-action';
import { Cidade } from '@bo/shared/models/localidade/cidade.model';

export const CIDADE_AUTOCOMPLETE_LOAD = '[CIDADE] AUTOCOMPLETE LOAD';
export const CIDADE_AUTOCOMPLETE_LOADED = '[CIDADE] AUTOCOMPLETE LOADED';


export class CidadeBuscarTermoAction implements NgRxAction<string> {
  readonly type = CIDADE_AUTOCOMPLETE_LOAD;

  constructor(public payload: string) {}
}

export class CidadeEncontradasPorTermoAction implements NgRxAction<Cidade[]> {
  readonly type = CIDADE_AUTOCOMPLETE_LOADED;

  constructor(public payload: Cidade[]) {}
}

export type CidadeActions = CidadeBuscarTermoAction
| CidadeEncontradasPorTermoAction
;
