import { InscricaoProcessoSeletivo } from './inscricao-processo-seletivo.model';
import { Vaga } from "./vaga.model";
import { StatusProcessoSeletivo } from "@bo/shared/models/enums/processo-seletivo.enum";
import { IModel } from "@senac/architecture";
import { Ofertante } from "@bo/shared/models/ofertante/ofertante.model";
import { Cidade } from "@bo/shared/models/localidade/cidade.model";
import { Unidade } from "../regional/unidade.model";

export class ProcessoSeletivo implements IModel
{
  public id: string;
  public descricao : string;
  public dataExpiracao : Date;
  public status: StatusProcessoSeletivo;
  public vaga : Vaga;
  public descricaoStatus? : string;
  public municipioDestino? : Cidade;
  public ofertante? : Ofertante;
  public inscricoes: InscricaoProcessoSeletivo[];
  public unidade? : Unidade;
  public inscricao? : InscricaoProcessoSeletivo;
}
