import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'senac-modal-material-confirm',
  templateUrl: './modal-material-confirm.component.html',
  styleUrls: ['./modal-material-confirm.component.scss']
})
export class ModalMaterialConfirmComponent {

  public title: string;
  public message: string;
  public okLabel = 'Ok';
  public showCancelButton = true;
  public icon = null;
  public iconSize = '64px';
  public iconColor = '#555';
  private defaultIconSize = this.iconSize;
  private defaultIconColor = this.iconColor;

  constructor(
    public dialogRef: MatDialogRef<ModalMaterialConfirmComponent>,
    private _sanitizer: DomSanitizer
  ) { }

  public setIcon(icon): void {
    if (icon && typeof icon === 'object') {
      this.icon = icon.icon || null;
      this.iconColor = icon.color || this.defaultIconColor;
      this.iconSize = icon.size || this.defaultIconSize;
    } else {
      this.icon = icon;
    }
  }

  get safeMessage(): SafeHtml {
    return this.getSafeIcon(this.message);
  }

  get safeIcon(): SafeHtml {
    const icon = `<i style="font-size:${this.iconSize};color:${this.iconColor}" class="material-icons">${this.icon}</i>`;
    return this.getSafeIcon(icon);
  }

  private getSafeIcon(html): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

}
